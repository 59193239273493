import reducer from './reducer';
import * as actionCreators from './action-creators';

export const { getGiftCardVariants, createGiftCard } = actionCreators;

export type {
  GiftCardsStateType,
  GiftCardActionsType,
  GiftCardCreateType,
  GiftCardVariantType,
  GiftCardType
} from './types';

export default reducer;
