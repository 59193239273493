import api from 'utils/huckberryApi';

import qs from 'qs';
import snakeCase from 'lodash/snakeCase';

export const fetchGoogleRecommendations = ({
  algorithm = 'recommended_pskus',
  productGroupIds
}: {
  algorithm: string;
  productGroupIds: number[];
}) =>
  api.get('/api/product_recommendations', {
    params: {
      algo: algorithm,
      product_group_id: productGroupIds
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: 'brackets' })
  });

export const fetchCartAddons = (cartAddOnType: 'default' | 'forYou') =>
  api.get('/api/cart_addons', {
    params: { cart_add_on_type: snakeCase(cartAddOnType) }
  });
