// @flow
import AlgoliaInsights from 'search-insights';

import { events as analytics, trackAction, trackLink } from 'modules/analytics';

import type { ActivePageFiltersState, PageTile } from 'modules/page';

import type { AlgoliaEventType } from 'modules/analytics/types';

export type BlockDataType = {|
  //admin label of page block clicked
  block_type: string,
  //index within blocks
  position?: number,
  //send null if there is optional title and no title is included, otherwise “titleless block”
  block_title: ?string,
  //position within the carousel (default to 1)
  placement?: number,
  //i.e. left image, right image (or button) and placement would be 1 and 2 respectively.
  placement_type?: string,
  //url of image
  image_url?: string,
  //link url
  link: string,
  asset_type?: string,
  tile_title?: string,
  label?: 'ymal' | 'cart-addons' | 'related-products',
  data_category?: string
|};

type ProductTrackingType = {|
  pageName: string,
  pageType: string,
  products: Array<PageTile>,
  slug: string,
  appliedFilters?: $Shape<ActivePageFiltersState>,
  index?: string
|};

export function pageBlockClick(blockData: BlockDataType) {
  trackLink(analytics.PAGE_BLOCK_CLICKED, blockData);
}

const convertProductTiles = (productTiles: Array<PageTile>) =>
  productTiles?.map((product: PageTile, index: number) => {
    if (product.payload.id) {
      return {
        product_id: product.payload.id,
        sku: product.payload.sku,
        category: product.payload.category,
        name: product.payload.name,
        brand: product.payload.brandTitle,
        price: product.payload.price,
        total: parseFloat(product.payload.price.replace('$', '')),
        position: index + 1,
        url: product.payload.url || '',
        image_url: product.payload.imageUrl || '',
        objectID: product.payload.sku || '',
        currency: 'USD'
      };
    }
  });

export const fetchAlgoliaQueryInfo = (property: 'indexName' | 'queryID') => {
  const storageKey = 'queryInfo';
  const queryInfoString = localStorage?.getItem(storageKey);
  if (queryInfoString) {
    const queryInfo = JSON.parse(queryInfoString);
    return queryInfo?.[property];
  }
  return '';
};

export function trackProductList(trackingData: ProductTrackingType) {
  const { pageName, pageType, products, slug } = trackingData;
  const analyticsData = {
    page_name: pageName,
    page_type: pageType,
    slug,
    products: convertProductTiles(products)
  };

  // This makes sure that Product List Viewed doesn't execute before router history change and Segment Page event
  setTimeout(() => {
    trackAction(analytics.PRODUCT_LIST_VIEWED, analyticsData);
  }, 0);
}

export function trackFilteredProductList(trackingData: ProductTrackingType) {
  const { pageName, pageType, products, slug, appliedFilters } = trackingData;

  const analyticsData = {
    page_name: pageName,
    page_type: pageType,
    slug,
    products: convertProductTiles(products)
  };

  trackAction(analytics.PRODUCT_LIST_FILTERED, {
    ...analyticsData,
    filters: appliedFilters
  });
}

export function trackAlgoliaEvent({ type, payload }: AlgoliaEventType) {
  const userToken =
    window?.rudderanalytics?.getAnonymousId &&
    window.rudderanalytics.getAnonymousId();

  const authenticatedUserToken =
    window?.rudderanalytics?.getUserId && window.rudderanalytics.getUserId();

  const index = fetchAlgoliaQueryInfo('indexName') || payload.index;

  AlgoliaInsights(type, {
    userToken,
    authenticatedUserToken,
    index,
    ...payload
  });
}
