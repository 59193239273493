// @flow

import './SoftGate.scss';

import * as React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import WelcomeView from './WelcomeView/WelcomeView';
import LoginView from './LoginView/LoginView';
import SignupView from './SignupView/SignupView';
import ForgotPasswordView from './ForgotPasswordView/ForgotPasswordView';
import UpdateEmailView from './UpdateEmailView/UpdateEmailView';

import HuckberryLogoHeader from 'components/AppContainer/Shared/HuckberryLogoHeader';
import SoftGateBackground from 'components/SoftGate/SoftGateBackground';

import useOnClickOutside from 'components/utils/useOnClickOutside';

import { events as analytics, trackAction } from 'modules/analytics';
import { close, changeView } from 'modules/auth-modal';

import type { Views, AuthDomain } from 'modules/auth-modal/types';

type Props = {|
  modalType: string,
  modalDomain: AuthDomain,
  view: Views
|};

const SoftGate = ({ modalType, modalDomain, view }: Props) => {
  const dispatch = useDispatch();

  const modalContainer = React.useRef(null);

  useOnClickOutside(modalContainer, () => handleClose('outside_click'));

  const handleClose = React.useCallback(
    (method: string) => {
      trackAction(analytics.SOFT_GATE_CLOSED, {
        method,
        label: modalDomain,
        context_step_sequence: view
      });

      dispatch(close());
    },
    [dispatch, modalDomain, view]
  );

  React.useEffect(() => {
    window.document.body.classList.add('SoftGate__no-scroll');
    window.document
      .getElementsByTagName('html')[0]
      ?.classList?.add('SoftGate__no-scroll');

    return () => {
      window.document.body.classList.remove('SoftGate__no-scroll');
      window.document
        .getElementsByTagName('html')[0]
        ?.classList?.remove('SoftGate__no-scroll');
    };
  }, []);

  const showHuckberryLogo = React.useMemo(() => !/journal/.test(modalDomain), [
    modalDomain
  ]);

  const softGateBackground = React.useMemo(() => {
    switch (modalDomain) {
      case 'plain':
      case 'promotion':
      case 'email-only':
      case 'exit-intent':
        return 'truck';
      case 'big-event-coming':
        return 'christmasTree';
      case 'sitewide-sale':
        return 'model';
      case 'pappy-day':
        return 'pappyDay';
      case 'journal':
        return 'journal';
      case 'cyber-week':
        return 'cyberWeek';
      case 'favorites':
      case 'back-in-stock':
        return null;
      default:
        return null;
    }
  }, [modalDomain]);

  const onChangeViewHandler = React.useCallback(
    (view: Views) => dispatch(changeView(view)),
    [dispatch]
  );

  const renderViews = React.useMemo(() => {
    switch (view) {
      case 'initial':
      case 'welcome':
        return (
          <WelcomeView
            onChangeView={onChangeViewHandler}
            modalDomain={modalDomain}
          />
        );
      case 'signup':
        return (
          <SignupView
            modalDomain={modalDomain}
            onClose={handleClose}
            onChangeView={onChangeViewHandler}
          />
        );
      case 'login':
        return (
          <LoginView
            onChangeView={onChangeViewHandler}
            modalDomain={modalDomain}
          />
        );
      case 'forgot-password':
        return (
          <ForgotPasswordView
            modalDomain={modalDomain}
            onChangeView={onChangeViewHandler}
          />
        );
      case 'update-email':
        return (
          <UpdateEmailView
            modalDomain={modalDomain}
            onChangeView={onChangeViewHandler}
          />
        );
      default:
        return (
          <WelcomeView
            onChangeView={onChangeViewHandler}
            modalDomain={modalDomain}
          />
        );
    }
  }, [handleClose, modalDomain, onChangeViewHandler, view]);

  return (
    <>
      <div
        className={classnames('SoftGate__modal', {
          'SoftGate__modal--full': modalType === 'full' || modalType === 'auth',
          'SoftGate__modal--short': modalType === 'short',
          'SoftGate__modal--portrait': modalType === 'portrait'
        })}
        ref={modalContainer}
      >
        {showHuckberryLogo && (
          <div className="SoftGate__header-logo">
            <HuckberryLogoHeader
              width={190}
              fill={modalDomain === 'cyber-week' ? '#000000' : '#FFFFFF'}
            />
            <h3
              className={classnames('SoftGate__header-subtitle', {
                black: modalDomain === 'cyber-week'
              })}
            >
              The one stop men&apos;s shop{' '}
            </h3>
          </div>
        )}
        <button
          className="SoftGate--close-btn"
          onClick={() => handleClose('x')}
        >
          +
        </button>
        {renderViews}
        <SoftGateBackground background={softGateBackground} />
      </div>
      <div className="SoftGate__overlay" />
    </>
  );
};

export default SoftGate;
