// @flow

import './EmailForm.scss';

import * as React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Input from '../Input';
import Button from '../Button';
import SocialButtons from '../SocialButtons';

import submitEmail from 'api/email-submission/optin';

import { validateEmail } from 'utils/formValidator';

import { events as analytics, trackAction } from 'modules/analytics';
import { newsletterOptin } from 'modules/auth-modal';

import type { FromType } from 'components/Authentication/AuthenticationContainer';

type Props = {|
  buttonValue: string,
  userEmail?: string | null,
  showSocialAuthActions?: boolean,
  onEmailChanged?: () => void,
  onClickFacebookSignup?: (from: FromType, newAuth?: boolean) => void,
  onClickGoogleSignup?: (from: FromType, newAuth?: boolean) => void,
  inlineStyle?: boolean,
  autoFocus?: boolean,
  trigger: string,
  cyberWeek?: boolean
|};

export type ErrorType = null | string;

const EmailForm = ({
  buttonValue,
  userEmail,
  showSocialAuthActions = true,
  onEmailChanged,
  onClickFacebookSignup,
  onClickGoogleSignup,
  inlineStyle,
  autoFocus = false,
  trigger,
  cyberWeek = false
}: Props) => {
  const dispatch = useDispatch();

  const emailInputRef = React.useRef<HTMLInputElement | null>(null);

  const [error, setError] = React.useState<ErrorType>(null);

  React.useEffect(() => {
    if (emailInputRef.current && userEmail) {
      emailInputRef.current.value = userEmail;
    }
  }, [userEmail]);

  const onSubmitTrack = React.useCallback(
    (email: string) => {
      trackAction(analytics.USER_NEWSLETTER_OPTIN_SUBMITTED, {
        email,
        button_title: buttonValue,
        trigger
      });
    },
    [buttonValue, trigger]
  );

  const onSuccessCallbackHandler = React.useCallback(
    (savedEmail: string) => {
      dispatch(newsletterOptin(savedEmail));
      onEmailChanged && onEmailChanged();
    },
    [dispatch, onEmailChanged]
  );

  const onSubmitHandler = React.useCallback(
    (event) => {
      event.preventDefault();

      if (emailInputRef.current) {
        const enteredEmail = emailInputRef.current.value;

        // When the Email Form is used by the update-email
        // view, we have to push the email to Klaviyo
        // only if the passed email is different from the entered
        if (enteredEmail !== userEmail) {
          if (validateEmail(enteredEmail)) {
            if (error) {
              setError(null);
            }

            onSubmitTrack(enteredEmail);
            submitEmail(
              enteredEmail,
              () => onSuccessCallbackHandler(enteredEmail),
              (code, message) => {
                if (code === 409) {
                  onSuccessCallbackHandler(enteredEmail);
                } else {
                  setError(message);
                }
              }
            );
          } else {
            setError('Please enter a valid email address');
          }
        } else {
          onSuccessCallbackHandler(enteredEmail);
        }
      }
    },
    [error, onSubmitTrack, onSuccessCallbackHandler, userEmail]
  );

  return (
    <>
      <form
        className={classnames('EmailForm', {
          EmailForm__inline: inlineStyle
        })}
        onSubmit={onSubmitHandler}
      >
        <Input
          inputRef={emailInputRef}
          placeholder="Your Email Address"
          required
          type="email"
          autoFocus={autoFocus}
        >
          {error && <Input.Error>{error}</Input.Error>}
        </Input>
        <div className="EmailForm__button">
          <Button value={buttonValue} type="submit" cyberWeek={cyberWeek} />
        </div>
      </form>
      {showSocialAuthActions && (
        <SocialButtons
          buttonsValue="Join with"
          onFacebookClick={onClickFacebookSignup}
          onGoogleClick={onClickGoogleSignup}
        />
      )}
    </>
  );
};

export default EmailForm;
