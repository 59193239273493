import { StyliticsStateType, StyliticsActions } from './types';

const initialState: StyliticsStateType = {
  loaded: false,
  seed: '',
  outfits: [],
  hasOutfits: false
};

const reducer = (state = initialState, action: StyliticsActions) => {
  switch (action.type) {
    case 'STYLITICS/FETCH_OUTFITS':
      return {
        ...state,
        loaded: false,
        seed: action.seed,
        outfits: [],
        hasOutfits: false
      };
    case 'STYLITICS/FETCH_OUTFITS_SUCCESS':
      return {
        ...state,
        loaded: true,
        outfits: action.outfits,
        hasOutfits: action.outfits.length > 0
      };
    case 'STYLITICS/FETCH_OUTFITS_FAILURE':
      return {
        ...state,
        loaded: true,
        seed: action.seed,
        outfits: [],
        hasOutfits: false
      };
    default:
      return state;
  }
};

export default reducer;
