// @flow

import * as React from 'react';

import DefaultUpdateEmailView from './DefaultUpdateEmailView';

import { events as analytics, trackAction } from 'modules/analytics';

import type { Views, AuthDomain } from 'modules/auth-modal/types';

type Props = {|
  modalDomain: AuthDomain,
  onChangeView: (Views) => void
|};

const UpdateEmailView = ({ modalDomain, onChangeView }: Props) => {
  React.useEffect(() => {
    trackAction(analytics.SOFT_GATE_STEP_VIEWED, {
      label: modalDomain,
      context_step_sequence: 'update-email'
    });
  }, [modalDomain]);

  switch (modalDomain) {
    case 'email-only':
    case 'promotion':
    case 'big-event-coming':
    case 'sitewide-sale':
    case 'pappy-day':
    case 'cyber-week':
      return <DefaultUpdateEmailView onChangeView={onChangeView} />;
    default:
      return null;
  }
};

export default UpdateEmailView;
