import './Button.scss';

import React from 'react';
import classnames from 'classnames';

type Props = {
  value: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  cyberWeek?: boolean;
};

const Button = ({
  value,
  type = 'button',
  onClick: onClickHandler,
  cyberWeek = false
}: Props) => (
  <button
    className={classnames('Button button--primary--yellow', {
      'cyberweek-green': cyberWeek
    })}
    type={type}
    onClick={onClickHandler}
  >
    {value}
  </button>
);

export default Button;
