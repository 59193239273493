// @flow

import * as React from 'react';

import JournalForgotPasswordView from './JournalForgotPasswordView';
import PortraitForgotPasswordView from './PortraitForgotPasswordView';
import PlainForgotPasswordView from './PlainForgotPasswordView';

import type { Views, AuthDomain } from 'modules/auth-modal/types';

import { events as analytics, trackAction } from 'modules/analytics';

type Props = {|
  modalDomain: AuthDomain,
  onChangeView: (Views) => void
|};

const ForgotPasswordView = ({ modalDomain, onChangeView }: Props) => {
  React.useEffect(() => {
    trackAction(analytics.SOFT_GATE_STEP_VIEWED, {
      context_step_sequence: 'forgot-password',
      label: modalDomain
    });
  }, [modalDomain]);

  switch (modalDomain) {
    case 'journal':
      return <JournalForgotPasswordView onChangeView={onChangeView} />;

    case 'back-in-stock':
    case 'favorites':
      return <PortraitForgotPasswordView onChangeView={onChangeView} />;
    case 'promotion':
    case 'email-only':
    case 'exit-intent':
    case 'big-event-coming':
    case 'sitewide-sale':
    case 'pappy-day':
    case 'cyber-week':
    case 'plain':
      return <PlainForgotPasswordView onChangeView={onChangeView} />;
    default:
      return null;
  }
};

export default ForgotPasswordView;
