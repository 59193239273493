import { SagaIterator } from 'redux-saga';
import { call, takeEvery, put, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import {
  getCartAddonProductsSuccess,
  getCartAddonProductsFailure,
  getCartAddonForYouProductsSuccess,
  getCartAddonForYouProductsFailure,
  getGoogleRecommendationsSuccess,
  getGoogleRecommendationsFailure
} from './action-creators';
import { fetchCartAddons, fetchGoogleRecommendations } from './requests';

import { ApplicationState } from 'types/rootState';
import { CartItem } from 'modules/cart';
import { ProductTilePayloadType } from 'types/products';
import { RelatedProductType } from 'modules/product/types';

function* cartAddonsSaga(): SagaIterator {
  yield takeEvery('CART_ADDONS/GET_CART_ADDON_PRODUCTS', getCartAddonProducts);
  yield takeEvery(
    'CART_ADDONS/GET_CART_ADDON_FOR_YOU_PRODUCTS',
    getCartAddonForYouProducts
  );
  yield takeEvery(
    'CART_ADDONS/GET_GOOGLE_RECOMMENDATIONS',
    getGoogleRecommendations
  );
}

function* getCartAddonProducts(): SagaIterator {
  try {
    const cartAddons = yield call(fetchCartAddons, 'default');
    yield put(getCartAddonProductsSuccess(cartAddons.data));
  } catch (err) {
    return getCartAddonProductsFailure('Error fetching cart addons');
  }
}

function* getCartAddonForYouProducts(): SagaIterator {
  try {
    const cartAddons = yield call(fetchCartAddons, 'forYou');
    yield put(getCartAddonForYouProductsSuccess(cartAddons.data));
  } catch (err) {
    return getCartAddonForYouProductsFailure(
      'Error fetching for you cart addons'
    );
  }
}

const filterProducts = (
  products: ProductTilePayloadType[] | RelatedProductType[],
  allCartSkus: string[]
) => {
  if (isEmpty(products)) {
    return [];
  }
  // Filter out products that are already in the cart
  return products.filter((product) => !allCartSkus.includes(product.sku));
};

function* getGoogleRecommendations(): SagaIterator {
  const cartItems = yield select((state: ApplicationState) => state.cart.items);
  if (isEmpty(cartItems)) {
    return;
  }

  const productGroupIds = cartItems.map(
    (item: CartItem) => item.product.productGroupId
  );
  const allCartSkus = cartItems.map(
    (item: CartItem) => item.product.productGroupId
  );

  try {
    const googleRecommendedProducts = yield call(fetchGoogleRecommendations, {
      algorithm: 'google_recommended_addons',
      productGroupIds: productGroupIds.join(',')
    });

    const filteredRecommendedProducts = filterProducts(
      googleRecommendedProducts.data,
      allCartSkus
    );

    if (isEmpty(filteredRecommendedProducts)) {
      return;
    }
    yield put(getGoogleRecommendationsSuccess(filteredRecommendedProducts));
  } catch {
    return getGoogleRecommendationsFailure(
      'Error fetching Google recommendations'
    );
  }
}

export default cartAddonsSaga;
