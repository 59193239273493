import { GiftCardVariantType, GiftCardType } from './types';

export const getGiftCardVariants = () => ({
  type: 'GIFT_CARD_VARIANTS/FETCH'
});

export const getGiftCardVariantsSuccess = (
  variants: GiftCardVariantType[]
) => ({
  type: 'GIFT_CARD_VARIANTS/FETCH_SUCCESS',
  variants
});

export const getGiftCardVariantsFailure = (error: string) => ({
  type: 'GIFT_CARD_VARIANTS/FETCH_FAILURE',
  error
});

export const createGiftCard = (giftCardData: GiftCardType) => ({
  type: 'GIFT_CARD/CREATE',
  giftCardData
});

export const createGiftCardSuccess = () => ({
  type: 'GIFT_CARD/CREATE_SUCCESS'
});

export const createGiftCardFailure = (error: string) => ({
  type: 'GIFT_CARD/CREATE_FAILURE',
  error
});
