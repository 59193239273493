import { SagaIterator } from 'redux-saga';
import { call, takeEvery, select, put } from 'redux-saga/effects';

import { fetchGiftCardVariants, createGiftCardRequest } from './requests';
import { GiftCardCreateType, GiftCardRequestErrorType } from './types';
import {
  getGiftCardVariantsSuccess,
  getGiftCardVariantsFailure,
  createGiftCardSuccess,
  createGiftCardFailure
} from './action-creators';

import { open } from 'modules/cart-widget/action-creators';
import { fetchRequest } from 'modules/cart';

function* giftCardsSaga(): SagaIterator {
  yield takeEvery('GIFT_CARD_VARIANTS/FETCH', getGiftCardVariants);
  yield takeEvery('GIFT_CARD/CREATE', createGiftCardSaga);
  yield takeEvery('GIFT_CARD/CREATE_SUCCESS', handleCartAdd);
}

function* getGiftCardVariants(): SagaIterator {
  try {
    const response = yield call(fetchGiftCardVariants);
    const { data } = response.data;
    yield put(getGiftCardVariantsSuccess(data));
  } catch (err: unknown) {
    const knownErrorType = err as GiftCardRequestErrorType;
    yield put(getGiftCardVariantsFailure(knownErrorType.response.data.error));
  }
}

function* createGiftCardSaga(action: GiftCardCreateType) {
  try {
    yield call(createGiftCardRequest, action.giftCardData);

    yield put(createGiftCardSuccess());
  } catch (err: unknown) {
    const knownErrorType = err as GiftCardRequestErrorType;
    yield put(createGiftCardFailure(knownErrorType.response.data.error));
  }
}

function* handleCartAdd(): SagaIterator {
  const { order } = yield select((state) => ({
    cart: state.cart,
    order: state.session.cart
  }));

  if (order.orderNumber) {
    yield put(fetchRequest(order.orderNumber, order.token));
    yield put(open());
  }
}

export default giftCardsSaga;
