import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'types/rootState';

interface AutoLinkType {
  to: string;
  target?: string;
  rel?: string;
  title?: string;
  onClick?: (event: React.SyntheticEvent<EventTarget>) => void;
  onMouseOver?: (event: React.SyntheticEvent<EventTarget>) => void;
  className?: string;
  children: React.ReactNode;
  id?: string;
  style?: {
    [styleAttribute: string]: string;
  };
}

const absoluteLinkPattern = /^(http|https)/i;

const AutoLink = (props: AutoLinkType) => {
  const { host } = useSelector(
    (state: ApplicationState) => state.session.config
  );
  const { to, children, id, ...linkProps } = props;
  // If there is no destination link, return as a div instead of clickable element
  if (to?.length <= 0) {
    return (
      <div {...linkProps} id={id}>
        {children}
      </div>
    );
  }

  // If URL begins with hash, use Anchor tag
  if (to && to[0] === '#') {
    return (
      <a {...linkProps} href={to}>
        {children}
      </a>
    );
  }

  // Removes any internal absolute links, so all absolute links after this
  // will be external links
  let processedTo = to;

  if (host) {
    processedTo = to?.replace(host, '');
  }

  if (absoluteLinkPattern.test(processedTo) || linkProps.target === '_blank') {
    return (
      <a
        {...linkProps}
        href={processedTo}
        target={linkProps.target || '_blank'}
        rel={linkProps.rel || 'noopener noreferrer'}
      >
        {children}
      </a>
    );
  }

  return (
    <Link {...linkProps} to={processedTo} id={id}>
      {children}
    </Link>
  );
};

export default AutoLink;
