import api from 'utils/huckberryApi';
import qs from 'qs';

export const fetchQuery = (queryIds: Array<string>) => {
  return api.get('/api/pages/queries', {
    params: {
      query_ids: queryIds
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    }
  });
};
